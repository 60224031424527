<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>{{ PageTitle }}</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openPaymentOrderModal(null)"
            ></el-button>

            <el-button
              type="primary"
              icon="el-icon-s-operation"
              size="medium"
              @click="openFilterPaymentOrderModal(null)"
            ></el-button>

            <el-button
              type="primary"
              icon="el-icon-sort"
              size="medium"
              @click="changePaymentOrderListByType(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="FilteredPayments"
      style="width: 100%; z-index: 0"
    >
      <el-table-column label="#" prop="ref"></el-table-column>
      <el-table-column
        v-if="IsIncomingPaymentOrder"
        prop="from.name"
        label="cliente"
      >
      </el-table-column>
      <el-table-column v-else prop="to.name" label="fornecedor">
      </el-table-column>
      <el-table-column
        label="data rec."
        :formatter="
          (r) =>
            r.received_at ? dateFormatter.format(new Date(r.received_at)) : '-'
        "
      >
      </el-table-column>
      <el-table-column
        label="data comp."
        :formatter="
          (r) => (r.paid_at ? dateFormatter.format(new Date(r.paid_at)) : '-')
        "
      >
      </el-table-column>
      <el-table-column
        label="valor"
        :formatter="
          (r) => (r.amount ? currencyFormatter.format(Number(r.amount)) : '-')
        "
      >
      </el-table-column>
      <el-table-column prop="code" label="Nº"> </el-table-column>
      <el-table-column prop="bank.name" label="Banco"> </el-table-column>
      <el-table-column
        v-if="IsIncomingPaymentOrder"
        prop="to.name"
        label="Repassado para"
      >
      </el-table-column>
      <el-table-column label="ações" :width="100">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openPaymentOrderModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="downloadPaymentOrder(cat.row)"
              >
                <i class="el-icon-download"></i
              ></el-button>
              <el-popconfirm
                @confirm="deletePaymentOrder(cat.row)"
                title="
              Remover cheque?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <payment-order-modal
      :showModal="showPaymentOrderModal"
      :payment_order="payment_order"
      @close-modal="showPaymentOrderModal = false"
      @should-update="fetchPaymentOrders"
    ></payment-order-modal>

    <filter-payment-order-modal
      :showModal="showFilterPaymentOrderModal"
      :payment_order="payment_order"
      @close-modal="showFilterPaymentOrderModal = false"
      @update-filters="updateFilters"
    ></filter-payment-order-modal>
  </el-card>
</template>

<script>
import PaymentOrderModal from "./modals/PaymentOrder.vue";
import {generatePDF} from "../services/reports";
import FilterPaymentOrderModal from "./modals/FilterPaymentOrders.vue";
export default {
  name: "PaymentOrders",
  components: { PaymentOrderModal, FilterPaymentOrderModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    payment_orders: null,
    filterOptions: {},
    payment_order: null,
    showPaymentOrderModal: false,
    showFilterPaymentOrderModal: false,
    dateFormatter: new Intl.DateTimeFormat("pt-BR"),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
  }),
  mounted() {
    this.fetchPaymentOrders();
  },
  computed: {
    PageTitle() {
      return this.IsIncomingPaymentOrder
        ? "Cheques recebidos"
        : "Cheques emitidos";
    },
    IsIncomingPaymentOrder() {
      return this.$route.params.type != "emitidos";
    },
    FilteredPayments() {
      return (
        this?.payment_orders?.filter(
          (p) =>
            p.type === (this.IsIncomingPaymentOrder ? "received" : "emitted")
        ) || []
      );
    },
  },
  methods: {
    downloadPaymentOrder(paymentOrder){
      const headers = [['banco', 'numero', 'valor', 'retorno', 'motivo']];
      const content = [
        [
          paymentOrder.bank.name,
          paymentOrder.code,
          this.currencyFormatter.format(Number(paymentOrder.amount)),
          paymentOrder.status_code,
          PaymentOrderStatusCodes[paymentOrder.status_code] || 'desconhecido'
        ]
      ];

      generatePDF({
        fileName: `Cheque devolvido.pdf`,
        addTableTitle: true,
        defaultFontSize: 13,
        orientation: 'landscape',
        headers,
        content,
        marginTop: 80,
        headerMessage: "Recibo para cheque devolvido ",
        addMargin: true,
        textHeader: 
        `        Cliente: ${paymentOrder.from?.name}

        Recebido em: ${this.dateFormatter.format(new Date(paymentOrder.created_at))}
        
        Data compensação: ${paymentOrder.paid_at ? this.dateFormatter.format(new Date(paymentOrder.paid_at)) : '-'}
        
        Vencimento: ${paymentOrder.expires_at ? this.dateFormatter.format(new Date(paymentOrder.expires_at)) : '-'}`,

        textFooter: `NOME: ________________________________\t\t\t\tASSINATURA: ____________________________________________`
      });
    },
    deletePaymentOrder(p) {
      fetch(`${this.$store.state.apiUrl}checks/${p.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchPaymentOrders();
          this.$notify({
            title: "Cheque removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Não foi possível remover o cheque",
            type: "error",
            message: e.message,
            position: "bottom-right",
          });
        });
    },
    openPaymentOrderModal(e) {
      (this.payment_order = e), (this.showPaymentOrderModal = true);
    },
    openFilterPaymentOrderModal(e) {
      (this.payment_order = e), (this.showFilterPaymentOrderModal = true);
    },
    changePaymentOrderListByType() {
      if (!this.IsIncomingPaymentOrder)
        return this.$router.replace({ path: "/cheques/recebidos" });
      else return this.$router.replace({ path: "/cheques/emitidos" });
    },
    updateFilters(filters) {
      this.filterOptions = filters;
      this.fetchPaymentOrders();
    },
    fetchPaymentOrders() {
      const url = new URL(`${this.$store.state.apiUrl}checks`);
      if (this.filterOptions && Object.keys(this.filterOptions))
        url.search = new URLSearchParams(this.filterOptions);
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.payment_orders = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
const PaymentOrderStatusCodes = {
  '11': 'Cheque sem fundos',
  '12': 'Cheque sem fundos 2ª apresentação',
  '13': 'Conta encerrada',
  '14': 'Prática espúria',
  '20': 'Cheque sustado ou revogado',
  '21': 'Cheque sustado ou revogado 2ª apresentação',
  '22': 'Divergência ou insuficiência de assinatura',
  '23': 'Cheque rasurado ou adulterado',
  '24': 'Bloqueio judicial ou determinação do Bacen',
  '25': 'Cancelamento ou revogação de inibição/protesto',
  '27': 'Feriado municipal não previsto',
  '28': 'Cheque sustado ou revogado por furto ou roubo',
  '30': 'Furto ou roubo de cheque'

}
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>