<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="space-between">
        <h4>Estado do cheque:</h4>
        <el-row type="flex" justify="end">
          <el-button
            type="text"
            @click="setPaymentStatus('opened')"
            :class="{ 'text-muted': payment_order?.status != 'opened' }"
            >Aberto</el-button
          >
          <el-button
            type="text"
            @click="setPaymentStatus('paid')"
            :class="{ 'text-muted': payment_order?.status != 'paid' }"
            >Pago</el-button
          >
          <el-button
            type="text"
            @click="setPaymentStatus('refused')"
            :class="{ 'text-muted': payment_order?.status != 'refused' }"
            >Recusado</el-button
          >
        </el-row>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Origem da conta:</h4>
      </el-row>
      <el-select remote v-model="payment_order_.firm_id" size="medium">
        <template #prefix>Empresa:</template>
        <el-option
          v-for="item in Firms"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Destino da conta:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-select
            :disabled="!IsIncomingPaymentOrder"
            :filter-method="
              (searchFilter) => fetchPartners(searchFilter, true, false)
            "
            filterable
            v-model="payment_order_.received_from"
            size="medium"
          >
            <template #prefix>Recebido de:</template>
            <el-option
              v-for="item in Partners"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :md="12">
          <el-select
            v-model="payment_order_.passed_to"
            size="medium"
            :filter-method="
              (searchFilter) => fetchPartners(searchFilter, false, true)
            "
            filterable
          >
            <template #prefix>Repassado para:</template>
            <el-option
              v-for="item in Partners"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="payment_order_.received_at"
            type="date"
            size="medium"
            placeholder="Data rec.:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>

        <el-col :md="12">
          <el-date-picker
            v-model="payment_order_.paid_at"
            type="date"
            size="medium"
            placeholder="Data comp.:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <base-input
        label="Valor:"
        hint="R$"
        v-model="payment_order_.amount"
        type="money"
      >
      </base-input>

      <base-input label="Número:" hint="2012" v-model="payment_order_.code">
      </base-input>
      <el-select
        v-model="payment_order_.bank_id"
        value-key="id"
        size="medium"
        remote
        filterable
        :remote-method="(query) => fetchBanks(query)"
      >
        <template #prefix>Banco:</template>
        <el-option
          v-for="item in Banks"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import PartnerService from "../../services/partners";
import BankService from "../../services/banks";
import FirmService from "../../services/firms";

export default {
  props: ["payment_order", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      payment_order_: this.payment_order || {},
      isLoadingSave: false,
      firms: null,
      banks: null,
      partners: null,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  watch: {
    payment_order(v) {
      this.payment_order_ = v || {};
      if (v?.firm) this.payment_order_.firm_id = v?.firm?.uid;
      if (v?.from) this.payment_order_.received_from = v?.from?.uid;
      if (v?.to) this.payment_order_.passed_to = v?.to?.uid;
      if (v?.bank) this.payment_order_.bank_id = v?.bank?.id;
    },
    showModal(v) {
      if (v) {
        this.payment_order_ = this.payment_order || {};
        if (this.payment_order?.firm)
          this.payment_order_.firm_id = this.payment_order.firm.uid;
        if (this.payment_order?.from)
          this.payment_order_.received_from = this.payment_order.from.uid;
        if (this.payment_order?.to)
          this.payment_order_.passed_to = this.payment_order.to.uid;
        if (this.payment_order?.bank)
          this.payment_order_.bank_id = this.payment_order.bank.uid;
      }
    },
  },
  mounted() {
    this.fetchFirms(true);
    this.fetchBanks();
    //this.fetchPartners(true);
  },
  computed: {
    IsIncomingPaymentOrder() {
      return this.$route.params.type != "emitidos";
    },
    Firms() {
      return this.firms || [];
    },
    ModalTitle() {
      return this.payment_order ? "Editar cheque" : "Cadastrar cheque";
    },
    SafeAddr() {
      return this?.payment_order_?.addr || {};
    },
    Banks() {
      return this.banks || [];
    },
    Partners() {
      return this.partners || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    async fetchFirms(shouldFetch) {
      if (shouldFetch) {
        const { firms } = await FirmService().index();

        if (firms) this.firms = firms;
      }
    },
    async fetchBanks(queryName) {
      const params = queryName ? { queryName } : {};

      const { banks } = await BankService().index(params);

      if (banks) this.banks = banks;
    },
    async fetchPartners(search, is_client = true, is_provider = true) {
      const params = { searchName: search, is_active: true };

      if (is_client) params.is_client = true;

      if (is_provider) params.is_provider = true;

      if (search) {
        const { partners } = await PartnerService().index(params);

        if (partners) this.partners = partners;
      }
    },
    save() {
      this.isLoadingSave = true;
      return this.payment_order
        ? this.updatePaymentOrder()
        : this.createPaymentOrder();
    },
    setPaymentStatus(status) {
      if (this.payment_order_.status != status) {
        const oldStatus = this.payment_order_.status;
        this.payment_order_.status = status;
        if (status === "refused") {
          this.$prompt("Informe o código de retorno do cheque", {
            confirmButtonText: "Ok",
            cancelButton: "Cancelar",
            inputPattern: /^[0-9]*$/,
            inputErrorMessage: "Código inválido",
          })
            .then((v) => {
              if (v.action === "confirm")
                this.payment_order_.status_code = v.value;
              else this.payment_order_.status = oldStatus;
            })
            .catch(() => {
              this.payment_order_.status = oldStatus;
            });
        }

        if (status === "paid") this.payment_order_.status_code = 0;
      }
    },
    createPaymentOrder() {
      fetch(`${this.$store.state.apiUrl}checks`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          ...this.payment_order_,
          type: this.IsIncomingPaymentOrder ? "received" : "emitted",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.payment_order_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar o cheque",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updatePaymentOrder() {
      fetch(`${this.$store.state.apiUrl}checks/${this.payment_order_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(this.payment_order_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar o cheque",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "PaymentOrderModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.text-muted {
  color: grey !important;
}
</style>
