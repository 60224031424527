<template>
  <el-dialog title="Filtrar cheques" v-model="shouldShow">
    <form>
      <el-row type="flex">
        <el-col> </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Período de vencimento:</h4>
      </el-row>
      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filters.expires_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filters.expires_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Período de entrada</h4>
      </el-row>
      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filters.received_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filters.received_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Período de pagamento</h4>
      </el-row>
      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filters.paid_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filters.paid_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Origem e Destino</h4>
      </el-row>

      <el-select
        v-model="filters.received_from"
        size="medium"
        remote
        filterable
        :remote-method="
          (searchQuery) =>
            fetchPartners({ is_client: true, search: searchQuery })
        "
      >
        <template #prefix>Recebido de:</template>
        <el-option
          v-for="item in Clients"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="filters.passed_to"
        size="medium"
        remote
        filterable
        :remote-method="
          (searchQuery) =>
            fetchPartners({ is_provider: true, search: searchQuery })
        "
      >
        <template #prefix>Repassado para:</template>
        <el-option
          v-for="item in Providers"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Identificação e Estado</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12"
          ><el-select v-model="filters.status" size="medium">
            <el-option
              v-for="item in CheckStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
            <template #prefix>Estado:</template>
          </el-select>
        </el-col>
        <el-col :md="12">
          <base-input
            label="Devolução:"
            hint="Número da devolução"
            v-model="filters.status_code"
          >
          </base-input>
        </el-col>
      </el-row>

      <base-input
        label="Número:"
        hint="número do cheque"
        v-model="filters.code"
      >
      </base-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="clearFilter"
          >Limpar filtros</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="updateFilters"
          >Filtrar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import PartnerService from "../../services/partners";
import FirmService from "../../services/firms";
export default {
  components: { BaseInput },
  props: ["payment_order", "showModal"],
  emits: ["close-modal", "update-filters"],
  data() {
    return {
      filters: { ...(this.bill || {}) },
      isLoadingSave: false,
      firms: null,
      clients: null,
      providers: null,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  mounted() {
    this.fetchFirms();
  },
  computed: {
    CheckStatus() {
      return [
        {
          label: "Pago",
          value: "paid",
        },
        {
          label: "Aberto",
          value: "opened",
        },
        {
          label: "Recusado",
          value: "refused",
        },
        {
          label: "Baixado",
          value: "canceled",
        },
      ];
    },
    Firms() {
      return this.firms || [];
    },
    Clients() {
      return this.clients || [];
    },
    Providers() {
      return this.providers || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    async fetchPartners({ is_client, is_provider, search }) {
      const { partners } = await PartnerService().index({
        is_client,
        is_provider,
        searchName: search,
      });

      if (partners) {
        if (is_client) this.clients = partners;
        if (is_provider) this.providers = partners;
      }
    },
    async fetchFirms() {
      const { firms } = await FirmService().index();

      if (firms) this.firms = firms;
    },
    save() {
      this.isLoadingSave = true;
      return this.payment_order ? this.updateFirm() : this.createFirm();
    },
    updateFilters() {
      this.$emit("update-filters", this.filters);
      this.shouldShow = false;
    },
    clearFilter() {
      this.filters = {};
      this.updateFilters();
    },
  },
  name: "FilterBillsModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>
